<template>
    <v-container fluid style="padding-bottom:80px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saleses"
                                    item-value="salesman_id"
                                    item-text="salesman_name"
                                    label="Sales"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                                <v-progress-linear
                                    :active="loading2"
                                    :indeterminate="loading2"
                                    absolute
                                    bottom
                                    color="deep-purple accent-4"
                                ></v-progress-linear>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="border-12 mt-1" color="info" elevation="2" style="padding: 15px;" @click="search()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                </v-card-title>
                                <v-data-table
                                    fixed-header
                                    height="490"
                                    :headers="headers"
                                    :items="purchases"
                                    :options.sync="options"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItems"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                    group-by="tr_id"
                                    show-group-by
                                >
                                    <template v-slot:[`item.activity`]="{ item }">
                                        <div v-html="item.activity">
                                        </div>
                                    </template>
                                    <template v-slot:[`item.distance`]="{ item }">
                                        {{ item.distance ? new Intl.NumberFormat('en-US', {maximumFractionDigits:2}).format(item.distance) : '' }}
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <span class="badge badge-info" v-if="item.approve_date === '1900-01-01 00:00:00.000' && item.reject_date === '1900-01-01 00:00:00.000'">Belum di setujui</span>
                                        <span class="badge badge-success" v-else-if="item.approve_date != '1900-01-01 00:00:00.000' && item.reject_date === '1900-01-01 00:00:00.000'">Sudah di setujui</span>
                                        <span class="badge badge-danger" v-else-if="item.reject_date != '1900-01-01 00:00:00.000'">Ditolak</span>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <div class="d-flex">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                        mdi-eye
                                                    </v-icon>
                                                </template>
                                                <span>View Map</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="showFileDetail(item)" v-on="on">
                                                        mdi-file-image
                                                    </v-icon>
                                                </template>
                                                <span>View Image</span>
                                            </v-tooltip>
                                        </div>
                                        
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title>Detail Location</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <div id="map_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialog_file_doc"
                persistent
                max-width="1300px"
                >
                    <v-card class="p-2 rounded-l">
                        <v-card-title class="text-center">
                            <v-row>
                                <h4
                                class="text-center"
                                style="
                                font-weight: 600;
                                color: #053d76;
                                margin-bottom: 15px;
                                margin-top: 2px;">
                                Report Sales
                                </h4>
                            <v-divider
                                style="
                                border-top: 3px solid #0078d4;
                                margin-top: 5px;"></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                                <v-row>
                                    <v-col class="col-12" sm="4" md="4">
                                        <v-card class="elevation-1 rounded-5">
                                            <v-card-text class="mt-15" v-if="imageUrl">
                                                <v-img :src=imageUrl ></v-img>
                                            </v-card-text>
                                            <v-card-text v-else>
                                                <v-carousel >
                                                    <v-carousel-item
                                                    v-for="(image, i) in images"
                                                    :key="image"
                                                    :title="image.str2"
                                                    >
                                                        <v-img :src=image.image ></v-img>
                                                    </v-carousel-item>
                                                </v-carousel>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col class="col-12" sm="8" md="8">
                                        <v-container fluid>
                                            <v-col cols="12">
                                                <h5 class="text-center" 
                                                style="
                                                font-weight: 600;
                                                color: #053d76;
                                                margin-bottom: 45px;
                                                margin-top: 2px;"><span>Report Sales Info</span></h5>
                                            </v-col>
                                            <v-row>
                                            <v-col class="col-12" sm="3" md="4">
                                                <h6 class="blue-lcd mb-1">Sales ID<span class="red--text"></span></h6>
                                                <v-text-field
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="salesid" 
                                                    disabled 
                                                ></v-text-field> 
                                            </v-col>
                                            <v-col class="col-12" sm="3" md="4">
                                                <h6 class="blue-lcd mb-1">Sales Name<span class="red--text"></span></h6>
                                                <v-text-field
                                                    dense
                                                    solo
                                                    clearable 
                                                    v-model="salesname"
                                                    disabled
                                                ></v-text-field> 
                                            </v-col>
                                            <v-col class="col-12" sm="3" md="4">
                                                <h6 class="blue-lcd mb-1">Customer Name<span class="red--text"></span></h6>
                                                <v-text-field
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="namacustomer"
                                                    disabled 
                                                ></v-text-field> 
                                            </v-col>
                                            <v-col class="col-12" sm="3" md="4">
                                                <h6 class="blue-lcd mb-1">Address<span class="red--text"></span></h6>
                                                <v-text-field
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="alamat"
                                                    disabled
                                                ></v-text-field> 
                                            </v-col>
                                            <v-col class="col-12" sm="3" md="4">
                                                <h6 class="blue-lcd mb-1">City<span class="red--text"></span></h6>
                                                <v-text-field
                                                    dense
                                                    solo
                                                    clearable
                                                    v-model="city"
                                                    disabled
                                                ></v-text-field> 
                                            </v-col>
                                            <v-col class="col-12" sm="3" md="4">
                                                <h6 class="blue-lcd mb-1">Date<span class="red--text"></span></h6>
                                                <v-text-field
                                                    dense
                                                    solo
                                                    clearable 
                                                    v-model="tr_date"
                                                    disabled
                                                ></v-text-field> 
                                            </v-col>
                                            <v-col class="col-12" sm="3" md="10">
                                                <h6 class="blue-lcd mb-1">Lt Location<span class="red--text"></span></h6>
                                                <v-text-field
                                                    dense
                                                    solo
                                                    clearable 
                                                    v-model="lt_location"
                                                    disabled
                                                ></v-text-field> 
                                            </v-col>
                                            <div class="col-lg-12 ">
                                                <h6 class="blue-lcd mb-1">Remark<span class="red--text"></span></h6>
                                                <v-textarea v-model="remark" label="" disabled clearable outlined></v-textarea>
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-spacer></v-spacer>
                            <v-btn class="rounded-l text-white"  color="red" @click.stop="dialog_file_doc=false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Sales Activity',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Today Visit',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            offices: [],
            office: '',
            saleses: [],
            sales: '',
            display: 'none',
            purchases: [],
            headers: [
                { text: 'TR ID', align: 'start', sortable: true, value: 'tr_id' },
                { text: 'Seq', align: 'start', groupable: false, sortable: true, value: 'seq_id' },
                { text: 'Salesman', align: 'start', groupable: false, sortable: true, value: 'nama' },
                { text: 'Customer', groupable: false, value: 'namacustomer' },
                { text: 'Id Cust', groupable: false, value: 'customerid' },
                { text: 'Address', groupable: false, value: 'alamat' },
                { text: 'City', groupable: false, value: 'city' },
                { text: 'Date', groupable: false, value: 'tr_date' },
                { text: 'Remark', groupable: false, value: 'remark' },
                { text: 'Activity', groupable: false, value: 'activity' },
                { text: 'Distance (Km)', groupable: false, value: 'distance' },
                { text: 'Plat', groupable: false, value: 'plat' },
                { text: 'KBP', groupable: false, value: 'kbp_id' },
                { text: 'Status', groupable: false, value: 'status' },
                { text: 'Actions', groupable: false,  value: 'actions', sortable: false },
            ],
            totalItems: 10,
            options: {},
            loading2: false,
            search_detail: '',
            po_items: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'KBT',
                appl_id : 'SALES ACTIVITY'
            },
            user_approve1: '',
            user_approve2: '',
            pagination: 1,
            mymap: '',
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            salesid: '',
            dialog_file_doc: false,
            imageUrl: '',
            images: [],
            salesid:'',
            namacustomer:'',
            alamat:'',
            tr_date:'',
            salesname:'',
            remark:'',
            city:'',
            lt_location:'',
            disabled: false
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getEnvConf()
        await this.getSales()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'SALESID') {

                        if (res.data.data[i]['var_value'] != null) {

                            this.salesid = res.data.data[i]['var_value']
                            this.sales = res.data.data[i]['var_value']
                            this.getSales()
                            this.disabled = true
                        } else {
                            this.disabled = false

                        }
                    }
                }

                // this.getPullData(1,10)

            })
        },
        search(){
            this.pagination = 1
            this.getPullData(1, 10, true)
        },
        close() {
            this.dialog = false
        },
        async showItem(item){
            this.po_item = item
            this.dialog = true
            this.loading = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        async getPullData(page = 1, itemsPerPage = 10, search){
            this.loading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/sr/sales_activity/today?salesid=${this.sales ? this.sales : ""}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.display = 'block'
                this.loading = false
                this.purchases = res.data.data
                this.totalItems = res.data.meta.total
                this.pagination = res.data.meta.current_page
            })
        },
        async getSales(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/sales?salesid=${this.salesid}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saleses = res.data
            });
        },          
        showFileDetail(item){
            console.log(item);
            this.namacustomer = item.namacustomer
            this.salesid = item.salesid
            this.remark = item.remark
            this.alamat = item.alamat
            this.city = item.city
            this.salesname = item.nama
            this.tr_date = item.tr_date
            this.lt_location = item.lt_location
            this.item = item.img_url
            this.dialog_file_doc = true
            if (item.img_url) {
                this.showImageModal(item.img_url)            
            } else {
                this.imageUrl = ''
            }
            this.images = item.images
            this.dialog_file_doc = true
        },
        showImageModal(imgUrl){

            console.log(imgUrl)
            // $('#imgModal').modal('show')
            this.imageUrl = process.env.VUE_APP_URL + imgUrl

        },
    },
    watch: {
        options: {
            handler () {
                const { page, itemsPerPage } = this.options
                if (this.display === 'block') {
                    this.getPullData(page, itemsPerPage)
                }
            },
            deep: true,
        }
    }
}
</script>